import { Badge } from "@bokio/elements/Badge/Badge";
import { Link } from "@bokio/elements/Link/Link";
import { LoadingText } from "@bokio/elements/Loading/LoadingText";
import { PromoBadge } from "@bokio/elements/PromoBadge/PromoBadge";
import { Config } from "@bokio/shared/config";
import { mergeClassNames } from "@bokio/utils/classes";

import type { MenuBadgeProps } from "../../utils/MenuHelper";
import type { LinkProps } from "@bokio/elements/Link/Link";
import type { PromoBadgeProps } from "@bokio/elements/PromoBadge/PromoBadge";
import type * as React from "react";

import * as styles from "./menuLink.scss";

export interface MenuLinkProps extends LinkProps {
	loading?: boolean;
	currentRoute?: string;
	currentPathExternal?: string;
	basePath?: string;
	isActive?: boolean;
	badge?: MenuBadgeProps | PromoBadgeProps;
}

export const MenuLink = ({
	className,
	loading,
	external,
	route,
	children,
	basePath,
	onClick,
	isActive,
	badge,
	testId,
}: MenuLinkProps) => {
	const classNames = mergeClassNames(
		className,
		isActive && styles.active,
		loading && styles.loading,
		loading && styles.placeholder,
	);
	const props: LinkProps = {
		onClick: (e: React.MouseEvent<HTMLAnchorElement>) => onClick && onClick(e),
		style: "none",
		className: styles.link,
	};

	if (route) {
		props.route = basePath ? basePath + route : route;
	}

	if (external) {
		props.external = basePath ? Config.env.apiUrl + basePath + external : external;
	}

	if (loading) {
		return (
			<div className={classNames}>
				<LoadingText />
			</div>
		);
	}

	return (
		<div className={classNames} data-testid={testId}>
			<Link {...props}>
				{children}
				{badge && ("name" in badge ? <Badge name={badge.name} color={badge.color} /> : <PromoBadge {...badge} />)}
			</Link>
		</div>
	);
};
