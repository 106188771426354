import { useLayoutEffect } from "react";

import type { RefObject } from "react";

export const useResizeObserver = <TElement extends HTMLElement, TRef extends RefObject<TElement>>({
	ref,
	onResize,
}: {
	ref: TRef;
	onResize: (rect: DOMRect) => void;
}) => {
	const hasRef = !!ref.current;
	useLayoutEffect(() => {
		if (!ref.current || !hasRef) {
			// SS 2025-01-14
			// In for example OcrRenderer the ref is not set on first render.
			return;
		}

		let frameCallbackId: number | null = null;

		const observer = new ResizeObserver(() => {
			frameCallbackId = requestAnimationFrame(() => {
				if (!ref.current) {
					throw new Error("measureRef is not set");
				}

				const rect = ref.current.getBoundingClientRect();
				onResize(rect);
			});
		});

		observer.observe(ref.current);

		const rect = ref.current.getBoundingClientRect();
		onResize(rect);

		return () => {
			if (frameCallbackId !== null) {
				cancelAnimationFrame(frameCallbackId);
			}
			observer.disconnect();
		};
	}, [onResize, ref, hasRef]);
};
