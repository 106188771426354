import { List, ListColumn, ListHeader, ListItem } from "@bokio/shared/components/List";

import { LoadingText } from "./LoadingText";
import { RenderRequestError } from "./RenderRequestError";

import type { LoadingProps } from "./LoadingProps";

export function LoadingList({ request, requests, children, hideSoftErrors }: LoadingProps) {
	const renderItem = () => {
		const width = Math.random() * 100 + "%";
		return (
			<ListItem>
				<ListColumn>
					<LoadingText width={width} />
				</ListColumn>
			</ListItem>
		);
	};

	return (
		<RenderRequestError request={request} requests={requests} showWhenLoaded={children} hideSoftErrors={hideSoftErrors}>
			<List>
				<ListHeader>
					<ListColumn>
						<LoadingText width="50%" />
					</ListColumn>
				</ListHeader>

				{renderItem()}
				{renderItem()}
				{renderItem()}
				{renderItem()}
				{renderItem()}
			</List>
		</RenderRequestError>
	);
}
