import logoInvertedSvg from "@bokio/assets/images/brandV2/bokio_logo_inverted.svg";
import logoSvg from "@bokio/assets/images/brandV2/bokio_logo.svg";
import { Link } from "@bokio/elements/Link/Link";
import GeneralLangFactory from "@bokio/lang/GeneralLangFactory";
import { mergeClassNames } from "@bokio/utils/classes";

import type * as React from "react";

import * as styles from "./bokioLogo.scss";

// Allowing only positive integers
type PositiveInt = `${1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9}${number | ""}`;

export interface BokioLogoProps {
	inverted?: boolean;
	className?: string;
	imageClassName?: string;
	route?: string;
	size?: PositiveInt;
}

const BokioLogo: React.FC<BokioLogoProps> = ({ inverted, className, imageClassName, route, size }: BokioLogoProps) => {
	const lang = GeneralLangFactory();
	const logoHeight = size ? `${size}px` : "24px";

	return (
		<Link className={mergeClassNames(styles.logo, className)} route={route} style="none">
			<img
				style={{
					height: logoHeight,
				}}
				className={imageClassName}
				src={inverted ? logoInvertedSvg : logoSvg}
				alt={lang.LogotypeAltText}
			/>
		</Link>
	);
};

export default BokioLogo;
