import * as React from "react";

import * as m from "@bokio/mobile-web-shared/core/model/model";
import { noop } from "@bokio/shared/utils";

import type { Steps } from "@bokio/elements/RatingToast/utils";

let messageCounter = 0;
export const generateMessageKey = () => {
	messageCounter++;
	messageCounter = messageCounter % (Number.MAX_SAFE_INTEGER - 1);
	return `message-${messageCounter}`;
};

import RatingType = m.Entities.Feedback.RatingType;
type FeatureAvailabilityConfig = m.Config.FeatureAvailabilityConfig;
export enum AppMessageType {
	NewAppVersion = "newappversion",
	BankSyncNotification = "banksyncnotification",
	DetailedError = "error",
	ErrorMessage = "message",
	SuccessMessage = "success",
	WarningMessage = "warning",
	BookkeepingRating = "bookkeepingrating",
	InfoMessage = "info",
	InvoicingRating = "invoicingrating",
	ClosureRating = "closurerating",
	VacationYearEndRating = "vacationYearEndRating",
	QuoteRating = "quoteRating",
	StripeNotification = "stripeNotification",
	TrialPlanNotification = "trialPlanNotification",
	ReceivingEInvoiceRating = "ReceiveingEInvoiceRating",
	CESFeedbackRating = "CESFeedbackRating",
	AgencyCESFeedbackRating = "AgencyCESFeedbackRating",
	ReleaseNoteUpdatesInAgency = "ReleaseNoteUpdatesInAgency",
	ReleaseNoteUpdatesInCompany = "ReleaseNoteUpdatesInCompany",
}

type HorizontalDirection = "left" | "right";

type AppMessageBase = { persist?: boolean; refrenceId?: string | undefined; testId?: string } & {
	position?: HorizontalDirection;
} & (
		| { type: AppMessageType.NewAppVersion }
		| { type: AppMessageType.BankSyncNotification; message: m.Bokio.Integration.Plaid.BankFeedEventDto }
		| {
				type: AppMessageType.DetailedError;
				title: string;
				content: React.ReactNode;
		  }
		| { type: AppMessageType.ErrorMessage; message: string }
		| { type: AppMessageType.SuccessMessage; title: string; content: React.ReactNode }
		| { type: AppMessageType.WarningMessage; message: string }
		| { type: AppMessageType.InfoMessage; message: string }
		| { type: AppMessageType.StripeNotification; message: string }
		| {
				type: AppMessageType.BookkeepingRating;
				ratingType: RatingType.RegularBookkeeping | RatingType.BankTransactionBookkeeping | RatingType.BankRules;
				renderStepTitle: (step: Steps) => string;
				renderStepDescription: (step: Steps) => string;
				additionalData: string;
				onCancel?: () => void;
		  }
		| {
				type: AppMessageType.InvoicingRating;
				ratingType: RatingType.RegularInvoicing;
				renderStepTitle: (step: Steps) => string;
				renderStepDescription: (step: Steps) => string;
				additionalData: string;
				onCancel?: () => void;
		  }
		| {
				type: AppMessageType.ClosureRating;
				ratingType: RatingType.DigitalYearEndClosure;
				renderStepTitle: (step: Steps) => string;
				renderStepDescription: (step: Steps) => string;
				additionalData: string;
				onCancel?: () => void;
		  }
		| {
				type: AppMessageType.VacationYearEndRating;
				ratingType: RatingType.VacationYearEnd;
				renderStepTitle: (step: Steps) => string;
				renderStepDescription: (step: Steps) => string;
				additionalData: string;
				onCancel?: () => void;
		  }
		| {
				type: AppMessageType.QuoteRating;
				ratingType: RatingType.RegularQuote;
				renderStepTitle: (step: Steps) => string;
				renderStepDescription: (step: Steps) => string;
				additionalData: string;
				onCancel?: () => void;
		  }
		| {
				type: AppMessageType.ReceivingEInvoiceRating;
				ratingType: RatingType;
				renderStepTitle: (step: Steps) => string;
				renderStepDescription: (step: Steps) => string;
				additionalData: string;
				onCancel?: () => void;
		  }
		| { type: AppMessageType.TrialPlanNotification; message: string }
		| {
				type: AppMessageType.CESFeedbackRating;
				ratingType: RatingType;
				renderStepTitle: (step: Steps) => string;
				renderStepDescription: (step: Steps) => string;
				additionalData: string;
				onCancel?: () => void;
		  }
		| {
				type: AppMessageType.AgencyCESFeedbackRating;
				ratingType: m.Entities.PartnerRatingType;
				renderStepTitle: (step: Steps) => string;
				renderStepDescription: (step: Steps) => string;
				additionalData: string;
				onCancel?: () => void;
		  }
		| {
				type: AppMessageType.ReleaseNoteUpdatesInAgency;
				noOfUpdates: number;
				message: string;
				onCloseWhatsNewFlyout: () => void;
		  }
		| {
				type: AppMessageType.ReleaseNoteUpdatesInCompany;
				noOfUpdates: number;
				message: string;
				agencyId: string;
		  }
	);

export type AddAppMessageRequest = {
	/**
	 * A fixed string for deduplicating similar/same messages.
	 * For messages with the same key, only the last one will be rendered in the view.
	 */
	deduplicateByKey?: string;
} & AppMessageBase;

export type AppMessage = AppMessageBase & { key: string };

export interface AppState {
	messages: AppMessage[];
	existsNewerVersion: boolean;
}

export interface AppActions {
	dismissMessage: (key: string) => void;
	addMessage: (message: AddAppMessageRequest) => void;
	reloadForNewVersion: (reason: string | undefined) => void;
}

export interface AppContext extends AppState, AppActions {
	featureAvailability: FeatureAvailabilityConfig;
	isBankAuthModalOpen: boolean;
	openBankAuthenticationModal: () => void;
	pushDisableSveaInternationalPaymentsAutoSyncOnAuth: () => void;
	popDisableSveaInternationalPaymentsAutoSyncOnAuth: () => void;
}

const defaultContextValue: AppContext = {
	existsNewerVersion: false,
	featureAvailability: {
		Country: "SE",
		RotRut: true,
		Salary: true,
		VatCheck: true,
		ReversedVAT: true,
		InvoiceRounding: true,
		SIE: true,
		Plaid: false,
		ImportantDates: true,
		TrialBalance: false,
		PartnerServiceK10: true,
		YearEndClosureSE: true,
		PrioSupport: true,
		FindAnAccountant: false,
		Billing: false,
		Plans: true,
		Cashback: true,
		BGFiles: true,
		NoMoneyTemplates: false,
		RequireCountrySubdivisionForBookkeeping: false,
		CreateCustomAccounts: true,
		Stripe: false,
		BokioBusinessAccount: false,
		Klarna: false,
		EnableBanking: false,
		SalaryFullPaymentSubmissions: false,
		EInvoice: true,
		BookkeepingSupport: false,
		Drilldown: false,
		SkatteverketSync: false,
		PublicApi: true,
	},
	messages: [],

	addMessage: noop,
	dismissMessage: noop,
	reloadForNewVersion: noop,
	isBankAuthModalOpen: false,
	openBankAuthenticationModal: noop,
	pushDisableSveaInternationalPaymentsAutoSyncOnAuth: noop,
	popDisableSveaInternationalPaymentsAutoSyncOnAuth: noop,
};

export const AppContext = React.createContext(defaultContextValue);

export const useAppContext = () => React.useContext(AppContext);
