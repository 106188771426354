import { useHelpContext } from "@bokio/contexts/HelpContext/HelpContext";
import Icon from "@bokio/elements/Icon/Icon";
import { LoadingProgressText } from "@bokio/elements/Loading/LoadingProgressText";
import { Notice } from "@bokio/elements/Notice/Notice";
import GeneralLangFactory from "@bokio/lang/GeneralLangFactory";
import htmlDecode from "@bokio/shared/utils/htmlDecode";
import { mergeClassNames } from "@bokio/utils/classes";

import type * as React from "react";

import * as styles from "./helpStyle.scss";

export interface HelpArticleProps {
	onGoBack?: () => void;
}

const wrapIframe = (html: string) =>
	html.replace("<iframe", '<div class="videoWrapper"><iframe').replace("</iframe>", "</iframe></div>");

export const HelpArticle: React.FC<HelpArticleProps> = ({ onGoBack }) => {
	const { activeHelpArticle, openArticleIsLoading, openArticleIsError } = useHelpContext();

	const lang = GeneralLangFactory();
	if (openArticleIsError) {
		return (
			<div className={styles.helpPageInner}>
				<div className={styles.articleNav}>
					<a className={styles.backLink} onClick={onGoBack}>
						<Icon name="left-open" size="12" />
						{lang.Back}
					</a>
				</div>
				<div className={styles.helpPageContent}>
					<Notice color="error">{lang.HelpCenter_ArticleError}</Notice>
				</div>
			</div>
		);
	}

	const content =
		openArticleIsLoading || !activeHelpArticle ? (
			<div style={{ padding: "20px" }}>
				<LoadingProgressText text={lang.Loading} width="100%" />
			</div>
		) : (
			<div className={styles.helpPageInner}>
				<div className={styles.articleNav}>
					<a className={styles.backLink} onClick={onGoBack}>
						<Icon name="left-open" size="12" />
						{lang.Back}
					</a>
					<a
						role="button"
						className={styles.helpPageNewWindow}
						target="_blank"
						href={activeHelpArticle.Url}
						rel="noopener noreferrer"
					>
						{lang.OpenInNewTab}
						<Icon name="external-link" />
					</a>
				</div>
				<div className={mergeClassNames(styles.helpPageContent, styles.helpScroll)}>
					<p className={styles.helpPageHeading}>{activeHelpArticle.Question}</p>
					{/* TODO find a "safe" way to render html */}
					{/* eslint-disable-next-line react/no-danger */}
					{<div dangerouslySetInnerHTML={{ __html: wrapIframe(htmlDecode(activeHelpArticle.Answer)) }} />}
				</div>
			</div>
		);
	return <div className={mergeClassNames(styles.helpPage, styles.helpScroll)}>{content}</div>;
};
