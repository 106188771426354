import * as React from "react";

import { useDeviceQuery } from "@bokio/elements/DeviceQuery/useDeviceQuery";
import {
	ExpandableSection,
	ExpandableSectionContent,
	ExpandableSectionHeader,
} from "@bokio/elements/ExpandableSection";
import { GeneralLangFactory } from "@bokio/lang";

import * as styles from "./testCompanyTopBar.scss";

export const TestCompanyTopBar = () => {
	const lang = GeneralLangFactory();
	const { isMobile } = useDeviceQuery();

	const [isExpanded, setExpanded] = React.useState(false);
	const toggleExpanded = () => setExpanded(!isExpanded);

	if (isMobile) {
		return (
			<ExpandableSection expanded={isExpanded} onChange={toggleExpanded}>
				<ExpandableSectionHeader
					className={styles.textHeader}
					headerText={lang.TestCompanyTopBar_Header}
					color="dark"
				/>
				<ExpandableSectionContent>
					<div className={styles.testCompanyTopBar}>
						<div className={styles.textContent}>
							<span className={styles.description}>{lang.TestCompanyTopBar_Description}</span>
						</div>
					</div>
				</ExpandableSectionContent>
			</ExpandableSection>
		);
	}

	return (
		<div className={styles.testCompanyTopBar} data-testid="TestCompanyTopBar">
			<div className={styles.textContent}>
				<span className={styles.textHeader}>{lang.TestCompanyTopBar_Header}</span>
				<span className={styles.description}>{lang.TestCompanyTopBar_Description}</span>
			</div>
		</div>
	);
};
