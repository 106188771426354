import { mergeClassNames } from "@bokio/utils/classes";

import { LoadingText } from "./LoadingText";
import { RenderRequestError } from "./RenderRequestError";

import type { LoadingProps } from "./LoadingProps";
import type * as React from "react";

import * as styles from "./loading.scss";

interface LoadingContentProps extends LoadingProps {
	children?: React.ReactNode;
	padding?: boolean;
	className?: string;
	lines?: number;
}

const makeRepeated = (arr: string[], repeats: number) => Array.from({ length: repeats }, () => arr).flat();

const lineWidths = ["80%", "60%", "70%", "30%"];

export const LoadingContent = ({
	request,
	children,
	requests,
	padding,
	className,
	hideSoftErrors,
	lines = 4,
}: LoadingContentProps) => {
	return (
		<RenderRequestError request={request} requests={requests} showWhenLoaded={children} hideSoftErrors={hideSoftErrors}>
			<div className={mergeClassNames(padding && styles.padding, className)}>
				{makeRepeated(lineWidths, Math.ceil(lines / lineWidths.length))
					.slice(0, lines)
					.map((width, index) => (
						<LoadingText width={width} className={styles.text} key={`LoadingText_${index}`} />
					))}
			</div>
		</RenderRequestError>
	);
};
