import * as React from "react";

import { useResizeObserver } from "@bokio/hooks/useResizeObserver/useResizeObserver";

import * as style from "./animations.scss";

interface SlideAnimationProps {
	isVisible?: boolean;
	children: React.ReactNode;
	targetWidth?: number;
	targetHeight?: number;
	inline?: boolean;
}

interface SlideAnimationState {
	realWidth?: number;
	realHeight?: number;
}

/**
 * Animate from hidden to an estimated size (width or height).
 * The width or height will be animated to the real size of the children
 * by measuring the children once it is rendered.
 * @example <SlideAnimation isVisible={true} targetHeight={100}>Content here</SlideAnimation>
 */
const SlideAnimation = ({ isVisible, inline, children, targetWidth, targetHeight }: SlideAnimationProps) => {
	const [state, setState] = React.useState<SlideAnimationState>({});
	const measureRef = React.useRef<HTMLDivElement>(null);

	const onResize = React.useCallback(
		rect => {
			if (!inline) {
				setState({
					realWidth: Math.round(rect.width),
					realHeight: Math.round(rect.height),
				});
			}
		},
		[inline],
	);

	useResizeObserver({ ref: measureRef, onResize });

	return (
		<div style={{ display: inline ? "inline-block" : "block", verticalAlign: "middle" }}>
			<div
				className={style.transitions}
				style={{
					opacity: isVisible ? 1 : 0,
					height: isVisible ? (targetHeight ? state.realHeight || targetHeight : "auto") : 0,
					width: isVisible ? (targetWidth ? state.realWidth || targetWidth : "auto") : 0,
					visibility: isVisible ? "visible" : "hidden",
				}}
			>
				<div ref={measureRef}>{children}</div>
			</div>
		</div>
	);
};

export default SlideAnimation;
