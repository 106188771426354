import { BoxContent, BoxHeader, BoxToolbar } from "@bokio/elements/Box";
import { mergeClassNames } from "@bokio/utils/classes";

import { LoadingContent } from "./LoadingContent";
import { LoadingText } from "./LoadingText";
import { RenderRequestError } from "./RenderRequestError";

import type { LoadingProps } from "./LoadingProps";
import type * as React from "react";

import * as styles from "./loadingBox.scss";

interface LoadingBoxProps extends LoadingProps {
	title?: string;
	toolbar?: boolean;
	spacingTop?: boolean;
	narrow?: boolean;
	center?: boolean;
	testId?: string;
}

export const LoadingBox: React.FC<React.PropsWithChildren<LoadingBoxProps>> = ({
	children,
	title,
	spacingTop,
	request,
	toolbar,
	narrow,
	className,
	center,
	hideSoftErrors,
	testId,
}) => {
	return (
		<RenderRequestError request={request} showWhenLoaded={children} hideSoftErrors={hideSoftErrors}>
			<div
				className={mergeClassNames(
					narrow && styles.narrow,
					spacingTop && styles.spacingTop,
					className,
					center && styles.center,
				)}
				data-testid={testId}
			>
				{title && <BoxHeader title={title} actions={{ fixed: <LoadingText width="80%" /> }} />}
				{toolbar && (
					<BoxToolbar>
						<LoadingText width="80%" />
					</BoxToolbar>
				)}
				<BoxContent>
					<LoadingContent />
				</BoxContent>
			</div>
		</RenderRequestError>
	);
};
