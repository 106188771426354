import { mergeClassNames } from "@bokio/utils/classes";

import { RenderRequestError } from "./RenderRequestError";

import type { LoadingProps } from "./LoadingProps";

import * as styles from "./loading.scss";

interface LoadingProgressProps extends LoadingProps {
	width?: string;
	children?: React.ReactNode;
	paddingTop?: boolean;
	paddingBottom?: boolean;
	padding?: boolean;
	testId?: string;
}

export function LoadingProgress({
	request,
	requests,
	children,
	hideSoftErrors,
	width,
	className,
	paddingTop,
	paddingBottom,
	padding,
	testId,
}: LoadingProgressProps) {
	const renderLoading = () => {
		const customStyle = { width: width || "100%" };
		const divClass = mergeClassNames(
			styles.progressLine,
			className,
			(paddingTop || padding) && styles.paddingTop,
			(paddingBottom || padding) && styles.paddingBottom,
		);

		return <div data-testid={testId} className={divClass} style={customStyle} />;
	};

	return (
		<RenderRequestError request={request} requests={requests} showWhenLoaded={children} hideSoftErrors={hideSoftErrors}>
			{renderLoading()}
		</RenderRequestError>
	);
}
