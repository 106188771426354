import * as React from "react";

import { getStatusOption } from "@bokio/backoffice/src/components/StatusSelect/OptimisticWorkStatusSelect";
import { Flyout } from "@bokio/components/Flyout/Flyout";
import { Button } from "@bokio/designsystem/components/Button";
import { CloseButton } from "@bokio/designsystem/components/CloseButton/CloseButton";
import { SG } from "@bokio/designsystem/components/SpacingGroup/SpacingGroup";
import { Div, Paragraph } from "@bokio/designsystem/components/TypographicElements/TypographicElements";
import { Badge } from "@bokio/elements/Badge/Badge";
import { EmptyState3 } from "@bokio/elements/EmptyState/EmptyState3";
import { SelectField } from "@bokio/elements/Form";
import Icon from "@bokio/elements/Icon/Icon";
import { Link } from "@bokio/elements/Link/Link";
import { LoadingContent, RenderRequestError } from "@bokio/elements/Loading";
import { formatMentionText } from "@bokio/elements/MentionsTextArea/MentionsTextArea";
import { BackOfficeLangFactory, GeneralLangFactory } from "@bokio/lang";
import { useCompanyInfo } from "@bokio/mobile-web-shared/core/contexts/CompanyInfoContext/CompanyInfoContext";
import * as m from "@bokio/mobile-web-shared/core/model/model";
import { useLazyApi } from "@bokio/mobile-web-shared/hooks/useApi/useApi";
import * as proxy from "@bokio/mobile-web-shared/services/api/proxy";
import { getRoute } from "@bokio/shared/route";
import { formatDate, formatMessage } from "@bokio/shared/utils/format";

import RenderRequest from "../../RenderRequest/RenderRequest";

import type { SelectFieldOption } from "@bokio/elements/Form/SelectField";
import type { RequestState } from "@bokio/mobile-web-shared/services/api/requestState";

import * as styles from "./accountantPanelFlyout.scss";

type AgencyTaskDto = m.Bokio.BackOffice.Notes.AgencyTaskDto;
type AgencyCommentDto = m.Core.Services.Notes.AgencyCommentDto;

const NoteItem: React.FC<{
	note: AgencyCommentDto | AgencyTaskDto;
	noteKind: m.Entities.NoteKind;
	partnerId: string;
	companyId: string;
	onRefresh: (org: string) => void;
}> = ({ note, noteKind, partnerId, companyId, onRefresh }) => {
	const lang = BackOfficeLangFactory();
	const generalLang = GeneralLangFactory();
	const routeToAgencyClientsNotesTab = getRoute("agencyClientDetails", {
		agencyId: partnerId,
		companyId: companyId,
		tab: "notes",
	});
	const readFullMessageLink = (
		<Link route={routeToAgencyClientsNotesTab}>{lang.AgencyClientOverview_ReadFullMessage}</Link>
	);

	const [markTaskAsDone, markTaskAsDoneRequest] = useLazyApi(
		proxy.Common.AccountantPanelController.MarkTaskAsDone.Post,
		{
			onSuccess: () => onRefresh(companyId),
		},
	);
	return (
		<Link
			route={getRoute("agencyClientDetails", {
				agencyId: partnerId,
				companyId: companyId,
				tab: "notes",
			})}
			style="none"
			className={styles.noteItem}
			data-testid="noteItem"
		>
			<Paragraph style="caption-default">
				{formatMessage(lang.AccountantPanel_Notes_CreatedBy, formatDate(note.TimeCreated), note.Author.Name)}
			</Paragraph>

			<div className={styles.notesText}>
				{note.State.Text.length > 100 ? (
					<Paragraph style="body-default">
						{formatMessage(
							lang.Notes_LimitedText,
							formatMentionText(note.State.Text).slice(0, 100),
							readFullMessageLink,
						)}
					</Paragraph>
				) : (
					<Paragraph style="body-default">{formatMentionText(note.State.Text)}</Paragraph>
				)}
			</div>

			<Div>
				{noteKind === m.Entities.NoteKind.Task && (
					<SG horizontal gap="space-between">
						{"TaskStatus" in note.State && (
							<Badge
								color={getStatusOption(note.State.TaskStatus).statusId}
								name={getStatusOption(note.State.TaskStatus).label}
							/>
						)}

						{markTaskAsDoneRequest.isLoading ? (
							<LoadingContent />
						) : (
							<Link onClick={() => markTaskAsDone(companyId, note.Id)}>
								<SG horizontal gap="8">
									<Icon name="check"></Icon>
									{generalLang.Todo_Mark_as_done}
								</SG>
							</Link>
						)}
					</SG>
				)}
			</Div>
			<RenderRequestError request={markTaskAsDoneRequest} />
		</Link>
	);
};

interface AccountantPanelFlyoutProps {
	companyId: string;
	isOpen: boolean;
	onClose: () => void;
}

export const AccountantPanelFlyout = ({ companyId, isOpen, onClose }: AccountantPanelFlyoutProps) => {
	const lang = BackOfficeLangFactory();
	const { companyInfo } = useCompanyInfo();
	const options: SelectFieldOption[] = [
		{
			value: m.Entities.NoteKind.Task,
			label: lang.AccountantPanel_MyTasks_Dropdown,
			testId: "AccountantPanel_TaskOption",
		},
		{
			value: m.Entities.NoteKind.Comment,
			label: lang.Comments,
			testId: "AccountantPanel_CommentOption",
		},
	];
	const [noteType, setNoteType] = React.useState<m.Entities.NoteKind>(m.Entities.NoteKind.Task);
	const partnerId = companyInfo.ActiveAgencyStatus?.PartnerId;

	const isTask = noteType === m.Entities.NoteKind.Task;

	const [setNotificationsStatuses] = useLazyApi(proxy.Common.AccountantPanelController.MarkNotesAsSeen.Post);

	const [getComments, getCommentsRequest] = useLazyApi(proxy.Common.AccountantPanelController.Comments.Get, {
		onSuccess: data => {
			const commentIds = data.map(n => n.Id);
			if (commentIds.length > 0) {
				setNotificationsStatuses(companyId, commentIds);
			}
		},
	});

	const [getTasks, getTasksRequest] = useLazyApi(proxy.Common.AccountantPanelController.Tasks.Get, {
		onSuccess: data => {
			const taskIds = data.map(n => n.Id);
			if (taskIds.length > 0) {
				setNotificationsStatuses(companyId, taskIds);
			}
		},
	});

	React.useEffect(() => {
		if (isOpen) {
			isTask ? getTasks(companyId) : getComments(companyId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen, companyId, noteType]);

	const renderNotes = (note: AgencyCommentDto | AgencyTaskDto) =>
		partnerId ? (
			<NoteItem
				note={note}
				key={note.Id}
				companyId={companyId}
				partnerId={partnerId}
				onRefresh={org => {
					isTask ? getTasks(org) : getComments(org);
				}}
				noteKind={noteType}
			/>
		) : undefined;

	const noteRequest: RequestState<m.Envelope<AgencyCommentDto[] | AgencyTaskDto[], m.SimpleError>> = isTask
		? getTasksRequest
		: getCommentsRequest;

	return (
		<Flyout
			asideClassName={styles.flyoutAside}
			backgroundClassName={styles.flyoutBackground}
			isOpen={isOpen}
			onClickOutside={onClose}
		>
			<div className={styles.header}>
				<div className={""}>
					<CloseButton onClick={onClose} />
				</div>
				<h3 className={styles.headerText}>{lang.TasksAndComments_Title}</h3>
				<div className={""}></div>
			</div>

			<div className={styles.actions}>
				<SG gap="space-between" horizontal>
					<SelectField className={styles.dropdown} options={options} value={noteType} onChange={setNoteType} />
					{partnerId && (
						<Button
							route={getRoute("agencyClientDetails", {
								agencyId: partnerId,
								companyId: companyInfo.Id,
								tab: "notes",
							})}
							type="link"
							onNavigation={onClose}
							testId={"AccountantPanel_ByråButton"}
						>
							{lang.AccountantPanel_OpenInBokioBackoffice}
						</Button>
					)}
				</SG>
			</div>

			<div className={styles.header}>
				<Paragraph style="caption-default">{lang.AccountantPanelHeader}</Paragraph>
			</div>

			<RenderRequest request={noteRequest} whenLoading={<LoadingContent />} useCacheWhenLoading>
				{notes => {
					return (
						<>
							{notes.length > 0 ? (
								<div className={styles.scrollable}>{notes.length > 0 && notes.map(n => renderNotes(n))}</div>
							) : (
								<div className={styles.emptyState}>
									<EmptyState3
										graphic="emptyStateAccountantPanel"
										title={
											isTask ? lang.AccountantPanel_EmptyStateTitle_Task : lang.AccountantPanel_EmptyStateTitle_Comment
										}
										message={
											isTask ? lang.AccountantPanel_EmptyStateDesc_Task : lang.AccountantPanel_EmptyStateDesc_Comment
										}
									/>
								</div>
							)}
						</>
					);
				}}
			</RenderRequest>
		</Flyout>
	);
};
